@font-face {
  font-family: "Rockness";
  src: local("Rockness"), url(assets/fonts/Rockness.otf) format("opentype");
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(assets/fonts/Avenir.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf4f8;
}

code {
  font-family: "Avenir";
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.1);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.5);
}

.MuiGrid-spacing-xs-2,
.MuiGrid-spacing-xs-4 {
  width: 100% !important;
  margin: 0px !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1200px !important;
  }
}

@media (min-width: 960px) {
  #SideNavigation {
    flex-grow: 0 !important;
    max-width: 10.666667% !important;
    flex-basis: 10.666667% !important;
  }

  #MainContent {
    flex-grow: 0 !important;
    max-width: 89.333333% !important;
    flex-basis: 89.333333% !important;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 150px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-color {
  color: #7ac8c3 !important;
}

.phone-icon {
  width: 28px !important;
  height: 30px !important;
}

.vertical-menu-icon {
  transform: rotate(90deg);
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.error {
  font-size: 12px !important;
  font-family: "Avenir" !important;
  color: #dc3545 !important;
  text-align: left !important;
  margin: 0px !important;
  padding-left: 20px !important;
  margin-top: -15px !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.button-raised {
  font-size: 14px;
  font-family: "Avenir";
  color: #fff !important;
  background: transparent linear-gradient(90deg, #7ac8c3 0%, #003361 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 11px 16px #7ac8c31a;
  border-radius: 100px;
  display: block;
  margin: 0px 10px !important;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  padding: 12px;
  cursor: pointer;
  letter-spacing: 2px;
  margin-top: 5px !important;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }
}

.button-stroke {
  font-size: 14px;
  font-family: "Avenir";
  color: #58595b !important;
  background-color: transparent;
  border: 1px solid #58595b;
  box-shadow: 0px 11px 16px #7ac8c31a;
  border-radius: 100px;
  display: block;
  margin: 0px 10px !important;
  outline: none;
  width: -webkit-fill-available;
  padding: 12px;
  cursor: pointer;
  letter-spacing: 2px;
  margin-top: 5px !important;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }
}

.button-disabled {
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: inherit;
}

@media (max-width: 600px) {
  .button-stroke,
  .button-raised {
    font-size: 12px;
    padding: 10px;
  }
}

.disabled {
  opacity: 0.3;
  cursor: context-menu !important;
}

.text-strike {
  text-decoration: line-through !important;
  opacity: 0.4 !important;
}

.menu-btn {
  margin: 0px 20px !important;
  width: 55px;
  height: 55px;
  background-color: rgba($color: #003361, $alpha: 0.1) !important;

  svg {
    fill: #003361;
  }
}

@media (max-width: 600px) {
  .menu-btn {
    margin: 0px 15px !important;
    width: 45px;
    height: 45px;
  }
}

// *Loader
.resend {
  animation: spinner 0.8s linear infinite;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 15%;
  width: 17px;
  height: 17px;
  margin-top: -10px;
  margin-left: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #b6b6b6;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 959px) {
  .text-sm-center {
    text-align: center !important;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }
}

@media (max-width: 600px) {
  .text-xs-center {
    text-align: center !important;
  }
}

// *Side Navigation
.navigation {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  height: 100vh;
  text-align: center;

  .logo {
    width: 100px;
    height: 90px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li,
    a {
      font-size: 14px;
      font-family: "Avenir";
      color: #7ac8c3;
      background-color: #fff;
      width: 100%;
      height: 100px;
      display: table;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      border-radius: 20px;
      margin: 15px 0px;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 28px #00000014;
        transition: 0.3s ease-in-out;
      }

      div {
        display: table-cell;
        vertical-align: middle;
      }

      svg {
        width: 25px;
        height: 25px;
        display: block;
        margin: 5px auto;
        fill: #7ac8c3;
      }
    }

    @media (max-width: 959px) {
      li,
      a {
        width: 120px;
      }
    }

    .active {
      box-shadow: 0px 3px 28px #00000014;
      transition: 0.3s ease-in-out;
    }
  }
}

@media (max-width: 959px) {
  .navigation {
    height: 100%;
  }

  .MuiDrawer-paper {
    width: 200px;
  }
}

// *Main Content
#MainContent {
  background-color: #ecf4f8;
  padding: 10px 20px;

  // *Header
  .header {
    padding: 20px 0px;
    border-bottom: 1px solid rgba(42, 42, 42, 0.1);

    h2 {
      font-size: 32px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      text-transform: capitalize;
    }

    h4 {
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      text-transform: capitalize;
      display: inline-block;
    }

    .logo {
      width: 120px;
      height: 100px;
    }

    .quote {
      position: relative;

      &:hover {
        p {
          opacity: 0;
          transition: 0.3s ease-in-out;
        }
        .sponsored {
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }

      p {
        font-size: 14px;
        font-family: "Avenir";
        color: #58595b;
        padding: 0px;
        cursor: inherit;
        transition: 0.3s ease-in-out;
      }

      .sponsored {
        position: absolute;
        left: 0px;
        top: 0px;
        padding: 2px 12px;
        background-color: rgba($color: #7ac8c3, $alpha: 0.4);
        border-radius: 20px;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease-in-out;

        span {
          font-size: 12px;
          font-family: "Avenir";
          color: #7ac8c3;
          margin-right: 70px;
        }

        @media (max-width: 600px) {
          span {
            margin-right: 30px;
          }
        }

        .link {
          font-size: 12px;
          font-family: "Avenir";
          color: #58595b;
          cursor: pointer;
        }
      }

      @media (max-width: 600px) {
        p {
          font-size: 12px;
        }
        .sponsored {
          padding-top: 4px;
          padding-bottom: 5px;
        }
      }

      @media (max-width: 959px) {
        .sponsored {
          text-align: left;
          padding-right: 0px;
          padding-top: 6px;
          padding-bottom: 7px;
        }
      }
    }

    .notification {
      margin: 0px 20px;
      width: 55px;
      height: 55px;
    }

    .notification {
      background-color: rgba($color: #003361, $alpha: 0.1);

      svg {
        fill: #003361;
      }
    }

    .dropdown {
      width: 310px;
      margin-top: 10px !important;
      left: -2% !important;
      z-index: 100;

      .MuiPaper-root {
        box-shadow: 0px 3px 99px #0000001a;
        border-radius: 20px;

        .MuiListItem-button:hover {
          background-color: transparent !important;
        }
      }

      h1 {
        font-size: 34px;
        font-family: "Rockness";
        color: #6ba570;
        text-align: center;
        margin: 8px 0px;
      }

      .notify-wrapper {
        height: auto;
        max-height: 400px;
        overflow-y: auto;
      }

      .MuiMenuItem-root {
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(42, 42, 42, 0.1);

        &:nth-last-child(1) {
          border-bottom: none;
        }

        .icon {
          background-color: rgba($color: #7ac8c3, $alpha: 0.2);
          text-align: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          position: relative;

          svg {
            fill: #7ac8c3 !important;
            width: 14px;
          }

          .unread {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #e94444;
          }
        }

        .head {
          display: flex;
          justify-content: space-between;
          margin-left: 15px;
          margin-bottom: 8px;

          span {
            font-size: 12px;
            font-family: "Avenir";
            color: rgba($color: #58595b, $alpha: 0.5);
          }
        }

        p {
          font-size: 12px;
          font-family: "Avenir";
          color: #58595b;
          margin-left: 15px;
        }
      }

      @media (max-width: 959px) {
        h1 {
          font-size: 30px;
        }
      }

      @media (max-width: 600px) {
        h1 {
          font-size: 28px;
        }
      }
    }

    @media (max-width: 959px) {
      .dropdown {
        width: 270px;
      }
    }

    @media (max-width: 600px) {
      h2 {
        font-size: 22px;
      }

      h4 {
        font-size: 13px;
      }

      .logo {
        width: 100px;
        height: 80px;
      }

      .notification {
        margin: 0px 15px;
        width: 45px;
        height: 45px;
      }

      .dropdown {
        width: 250px;
      }
    }
  }

  @media (max-width: 959px) {
    .header {
      padding-top: 0px;
    }
  }

  // *Breadcrumbs
  .MuiBreadcrumbs-root {
    padding: 15px 0px;

    .MuiBreadcrumbs-li {
      p {
        font-size: 16px;
        font-family: "Avenir";
        font-weight: 900;
        color: #58595b;
        opacity: 0.7;

        &:hover {
          text-decoration: none !important;
        }
      }

      @media (max-width: 600px) {
        p {
          font-size: 14px;
        }
      }
    }
  }

  // *My Mission
  .mission {
    height: calc(100vh - 205px);
    overflow-y: auto;

    .wrapper {
      margin-right: 10px !important;
      flex-grow: 0 !important;
      max-width: 18.8% !important;
      flex-basis: 18.8% !important;
    }

    .task-box {
      width: 100%;
      height: auto;
      background-color: #fff;
      border: 1px solid;
      border-radius: 12px;
      margin-bottom: 12px !important;
      opacity: color 0.5;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 23px #0000000f;
        transition: 0.3s ease-in-out;
      }

      .header {
        border-radius: 11px;
        padding: 8px 8px;
        opacity: background-color 0.1;

        .MuiIconButton-root {
          background-color: rgba($color: #003361, $alpha: 0.1);
          width: 30px;
          height: 30px;
        }

        h5 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #58595b;
          padding-right: 5px;
          word-break: break-word;
        }

        h6 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #7ac8c3;
        }
      }

      .content {
        padding: 4px;
        padding-top: 6px;

        .task {
          display: flex;
          padding: 2px 0px;
          background-color: #fff;

          .checkbox {
            position: relative;

            input[type="checkbox"] {
              visibility: hidden;
            }

            input[type="checkbox"]:checked + label {
              background-color: #7ac8c3;
              border-color: #7ac8c3;
            }

            input[type="checkbox"]:checked + label:after {
              opacity: 1;
            }

            input {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #7ac8c3;
              background-color: white;
              margin-right: 5px;
              vertical-align: middle;
              appearance: none;
              -webkit-appearance: none;
              outline: none;
              cursor: pointer;

              &:checked {
                background-color: #7ac8c3;
              }
            }

            label {
              border: 1px solid #7ac8c3;
              background-color: white;
              border-radius: 50%;
              cursor: pointer;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 2px;
              top: 1px;

              &::after {
                content: "";
                width: 9px;
                height: 5px;
                position: absolute;
                left: 2px;
                top: 3px;
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                opacity: 0;
                transform: rotate(-45deg);
              }
            }
          }

          p {
            font-size: 14px;
            font-family: "Avenir";
            color: #58595b;
            opacity: 0.8;
            margin-bottom: 7px;
            width: 80%;
            word-break: break-word;
          }

          .deleted-task {
            background-color: rgba($color: #e94444, $alpha: 0.2);
            width: 26px;
            height: 26px;
            padding: 8px;

            &:hover {
              background-color: rgba($color: #e94444, $alpha: 0.2) !important;
            }

            svg {
              fill: #e94444 !important;

              path {
                fill: #e94444 !important;
              }
            }
          }

          .add-sub-task {
            width: 78%;
            border: none;
            outline: none;
            font-size: 14px;
            font-family: "Avenir";
            color: #58595b;
            opacity: 0.8;
          }
        }

        .add-subtask {
          display: flex;
          padding: 5px;

          svg {
            fill: #58595b !important;
            margin-right: 10px;
            opacity: 0.6;

            path {
              fill: #58595b !important;
              opacity: 0.6;
            }
          }

          p {
            font-size: 14px;
            font-family: "Avenir";
            color: #58595b;
            opacity: 0.6;
          }
        }
      }
    }

    .add-task {
      svg {
        vertical-align: text-top;
      }
    }

    @media (max-width: 959px) {
      .wrapper {
        flex-grow: 0 !important;
        max-width: 31% !important;
        flex-basis: 31% !important;
      }
    }

    @media (max-width: 600px) {
      .wrapper {
        flex-grow: 0 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin: 0px !important;
      }
    }
  }

  // *My Thought
  .thought {
    height: calc(100vh - 205px);
    overflow-y: auto;

    .thought-box {
      width: 100%;
      height: auto;
      background-color: #fff;
      border: 1px solid #7ac8c3;
      border-radius: 12px;
      margin-right: 10px !important;
      margin-bottom: 15px !important;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 23px #0000000f;
        transition: 0.3s ease-in-out;
      }

      .header {
        background-color: rgba($color: #7ac8c3, $alpha: 0.1);
        border-radius: 11px;
        padding: 10px 8px;

        .MuiIconButton-root {
          background-color: rgba($color: #003361, $alpha: 0.1);
          width: 33px;
          height: 33px;
        }

        h5 {
          font-size: 15px;
          font-family: "Avenir";
          font-weight: 900;
          color: #58595b;
          word-break: break-word;
        }

        h6 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #7ac8c3;
        }
      }

      .content {
        padding: 10px 8px;

        p {
          font-size: 14px;
          font-family: "Avenir";
          color: #58595b;
          opacity: 0.8;
          margin-bottom: 7px;
          white-space: pre-line;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        span {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 700;
          color: rgba($color: #7ac8c3, $alpha: 0.7);
          cursor: pointer;
        }
      }
    }

    @media (min-width: 960px) {
      .thought-box {
        flex-grow: 0;
        max-width: 24%;
        flex-basis: 24%;
      }
    }

    @media (max-width: 959px) {
      .thought-box {
        flex-grow: 0;
        max-width: 48%;
        flex-basis: 48%;
      }
    }

    @media (max-width: 600px) {
      .thought-box {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin-right: 0px !important;
      }
    }
  }

  // *Write Thought
  .write-thought {
    .title {
      font-size: 26px;
      font-family: "Avenir";
      font-weight: 900;
      color: rgba($color: #003361, $alpha: 0.4);
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      margin: 10px 0px;

      &::placeholder {
        color: rgba($color: #003361, $alpha: 0.2);
      }
    }

    .description {
      font-size: 16px;
      font-family: "Avenir";
      color: rgba($color: #003361, $alpha: 0.4);
      width: 100%;
      border: none;
      background: transparent;
      outline: none;
      height: calc(100vh - 335px);
      margin: 10px 0px;
      resize: none;
      white-space: pre-line;

      &::placeholder {
        color: rgba($color: #003361, $alpha: 0.2);
      }
    }

    .error {
      padding-left: 0px !important;
    }

    @media (max-width: 600px) {
      .title {
        font-size: 24px;
        margin: 5px 0px;
      }

      .description {
        font-size: 14px;
        margin: 5px 0px;
        min-height: 120px;
      }
    }
  }

  // *Read Thought
  .read-thought {
    .title {
      font-size: 30px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      width: 100%;
      margin: 10px 0px;
      word-break: break-word;
    }

    .description {
      font-size: 16px;
      font-family: "Avenir";
      color: #003361;
      line-height: 1.8;
      width: 100%;
      height: calc(100vh - 335px);
      overflow-y: auto;
      margin: 10px 0px;
      white-space: pre-line;
      word-break: break-word;
    }

    @media (max-width: 959px) {
      .title {
        font-size: 26px;
      }

      .description {
        font-size: 14px;
      }
    }

    @media (max-width: 600px) {
      .title {
        font-size: 24px;
      }

      .description {
        font-size: 12px;
      }
    }
  }

  // *Add Task & Thought Dotted Box
  .add-task,
  .add-thought {
    padding: 10px 15px;
    border: 2px dashed rgba($color: #003361, $alpha: 0.4);
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

    &:hover {
      border: 2px dashed rgba($color: #003361, $alpha: 1);
      transition: 0.3s;

      svg {
        fill: #003361 !important;

        path {
          fill: #003361 !important;
        }
      }

      span {
        color: rgba($color: #003361, $alpha: 1);
      }
    }

    svg {
      vertical-align: bottom;
      margin-right: 5px;
      fill: rgba($color: #003361, $alpha: 0.2) !important;

      path {
        fill: rgba($color: #003361, $alpha: 0.2) !important;
      }
    }

    span {
      font-size: 15px;
      font-family: "Avenir";
      color: rgba($color: #003361, $alpha: 0.3);
    }

    @media (max-width: 600px) {
      span {
        font-size: 13px;
      }
    }
  }

  @media (min-width: 960px) {
    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 24%;
      flex-basis: 24%;
    }
  }

  @media (max-width: 959px) {
    .mission,
    .thought {
      height: 100%;
    }

    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 48%;
      flex-basis: 48%;
    }
  }

  @media (max-width: 600px) {
    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
      margin-right: 0px !important;
    }
  }

  // *Settings
  .setting {
    .heading {
      font-size: 26px;
      font-family: "Avenir";
      font-weight: 900;
      color: rgba(0, 51, 97, 0.4);
      margin-bottom: 10px;
    }

    .account,
    .app-setting,
    .user-decision {
      margin-bottom: 40px;

      .input-field {
        margin: 20px 10px;
        padding: 5px;
        width: 70%;
        border-bottom: 1px solid rgba($color: #2a2a2a, $alpha: 0.1);

        .MuiSwitch-colorSecondary.Mui-checked {
          color: #fff !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background-color: #003361 !important;
          opacity: 1 !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked:hover {
          background-color: rgba($color: #003361, $alpha: 0.04);
        }

        .icon {
          display: inline-block;

          svg {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            vertical-align: super;
          }
        }

        .align-icon {
          vertical-align: middle;
          margin-top: 8px;
        }

        .input-text {
          display: inline-block;
          padding: 0px 5px;
          width: 80%;

          .time-interval {
            width: 150px !important;
          }

          .MuiFormControl-root {
            width: 100%;
          }

          .MuiSelect-select {
            font-size: 16px;
            font-family: "Avenir";
            color: rgba(42, 42, 42, 0.4);
            background-color: transparent;
            padding-top: 7px;
            padding-bottom: 0px;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border: none !important;
          }

          label {
            font-size: 14px;
            font-family: "Avenir";
            font-weight: 900;
            color: rgba($color: #003361, $alpha: 0.4);
            display: block;
          }

          input {
            border: none;
            margin: 0px !important;
            background: transparent;
            color: rgba($color: #2a2a2a, $alpha: 0.4);
            font-size: 16px;
            outline: none;
            line-height: 1.5;
          }

          P {
            font-size: 18px;
            font-family: "Avenir";
            color: rgba(42, 42, 42, 0.4);

            .MuiSwitch-root {
              margin-left: 15px;
            }
          }

          @media (max-width: 600px) {
            label {
              font-size: 12px;
            }

            input {
              font-size: 14px;
            }

            P {
              font-size: 16px;

              .MuiSwitch-root {
                margin-left: 35px;
              }
            }
          }
        }
      }

      @media (max-width: 959px) {
        .input-field {
          width: 100%;
          margin: 15px 10px;
        }
      }
    }

    @media (max-width: 600px) {
      .heading {
        font-size: 24px;
        margin-bottom: 0px;
      }

      .account,
      .app-setting,
      .user-decision {
        margin-bottom: 20px;
      }
    }
  }
}

// *Forms
.form-bg {
  background-image: url(assets/images/BG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;

  .form-wrapper {
    background-color: transparent;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -35%);
    padding: 25px;
    text-align: center;
    width: 40%;

    .logo {
      width: 230px;
    }

    form {
      background-color: rgba($color: #fff, $alpha: 0.2);
      margin-top: 40px;
      padding: 20px;
      border-radius: 27px;

      h2 {
        font-size: 36px;
        font-family: "Rockness";
        color: #fff;
        margin: 15px 0px;
      }

      .input-field {
        margin: 20px 10px;
        padding: 5px;
        border: 1px solid rgba($color: #fff, $alpha: 0.6);
        border-radius: 100px;

        .MuiListItem-root {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }

        .MuiInput-underline:before,
        .MuiInput-underline:after {
          display: none !important;
        }

        .MuiSelect-select:focus {
          background-color: transparent !important;
        }

        .MuiSelect-icon {
          display: none;
        }

        .MuiSelect-select.MuiSelect-select {
          padding: 0px !important;
        }

        .MuiSelect-selectMenu {
          height: 30px;
        }

        .icon {
          display: inline-block;
          border-radius: 50%;
          background-color: #fff;
          padding: 5px 8px;
          text-align: center;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        input {
          border: none;
          margin: 0px 5px;
          background: transparent;
          color: #fff;
          font-size: 16px;
          outline: none;
          line-height: 1.5;
          width: calc(100% - 45px);

          &::placeholder {
            color: rgba($color: #fff, $alpha: 0.7);
          }
        }
      }

      .verification-input {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
        background-color: transparent;
        color: #fff;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
        // display: inline-block;
        margin: 0px 8px 20px 8px;
        width: 30px;
      }

      p {
        font-size: 14px;
        font-family: "Avenir";
        color: rgba($color: #fff, $alpha: 0.8);
        margin-bottom: 10px;
      }

      @media (max-width: 600px) {
        h2 {
          font-size: 30px;
        }

        .input-field {
          input {
            font-size: 14px;
          }
        }

        .verification-input {
          font-size: 22px;
          margin: 0px 3px 15px 3px;
          width: 25px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    p {
      font-size: 14px;
      font-family: "Avenir";
      color: rgba($color: #fff, $alpha: 0.7);

      .MuiSvgIcon-root {
        vertical-align: sub;
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }

    @media (max-width: 959px) {
      .logo {
        width: 170px;
        height: 120px;
      }

      form {
        margin-top: 0px;
        padding: 15px;
      }
    }

    @media (max-width: 600px) {
      .logo {
        width: 140px;
        height: 120px;
      }

      form {
        margin-top: 0px;
        padding: 10px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 959px) {
    .form-wrapper {
      width: 70%;
      margin: auto;
      position: initial;
      left: 0px;
      top: 0px;
      transform: none;
    }
  }

  @media (max-width: 600px) {
    .form-wrapper {
      width: auto;
    }
  }
}

@media (max-width: 320px) {
  .form-bg {
    height: 100%;
  }
}

// *Menu Drop Down
.menu-option {
  .MuiPaper-root {
    box-shadow: none !important;
    background-color: transparent !important;
    margin-top: 10px;

    .MuiList-padding {
      padding: 0px !important;
    }
  }

  .option-wrapper {
    background-color: #fff;
    border-radius: 50%;
  }

  .edit {
    background-color: rgba($color: #7ac8c3, $alpha: 0.2);
    width: 38px;
    height: 38px;
    padding: 7px;
    display: block;

    &:hover {
      background-color: rgba($color: #7ac8c3, $alpha: 0.2) !important;
    }

    svg {
      fill: #7ac8c3 !important;

      path {
        fill: #7ac8c3 !important;
      }
    }
  }

  .deleted {
    background-color: rgba($color: #e94444, $alpha: 0.2);
    width: 38px;
    height: 38px;
    padding: 10px;
    margin-top: 5px;
    display: block;

    &:hover {
      background-color: rgba($color: #e94444, $alpha: 0.2) !important;
    }

    svg {
      fill: #e94444 !important;

      path {
        fill: #e94444 !important;
      }
    }
  }
}

// *Dialog
.MuiDialog-paper {
  margin: 0px !important;
  padding: 15px 5px !important;
  border-radius: 20px !important;
}

.MuiDialogTitle-root {
  padding: 0px !important;
  padding-top: 16px !important;
  width: 580px;

  h2 {
    font-size: 36px;
    font-family: "Rockness" !important;
    color: #6ba570;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .MuiDialogTitle-root {
    width: 300px;
  }
}

.MuiDialogContent-root {
  .error {
    margin: 0px !important;
    padding: 0px !important;
  }

  .daily-quote,
  .author,
  .sponsor,
  .task-title {
    font-size: 18px;
    font-weight: 900;
    font-family: "Avenir";
    color: rgba($color: #003361, $alpha: 0.4);
    padding: 0px;
    margin: 0px;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;

    &::placeholder {
      color: rgba($color: #003361, $alpha: 0.2);
    }
  }

  .author {
    font-size: 14px;
    margin-top: 10px;
  }

  .sponsor {
    font-size: 14px;
    margin-top: 35px;
  }

  .task-color {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    width: 94%;

    .radio-container {
      display: block;
      position: relative;
      cursor: pointer;
      height: 30px;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .radio-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* When the radio button is checked, add a blue background */
    .radio-container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-container .checkmark:after {
      transform: translate(45%, 45%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: white;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 900;
    font-family: "Avenir";
    color: rgba($color: #003361, $alpha: 0.4);
    width: 500px;
    text-align: center;
  }

  @media (max-width: 600px) {
    .daily-quote,
    .author,
    .sponsor,
    .task-title {
      font-size: 16px;
    }

    .task-color {
      .radio-container {
        height: 25px;
        font-size: 20px;
      }

      /* Create a custom radio button */
      .checkmark {
        height: 25px;
        width: 25px;
      }

      /* Style the indicator (dot/circle) */
      .radio-container .checkmark:after {
        transform: translate(40%, 40%);
        width: 14px;
        height: 14px;
      }
    }

    h5 {
      font-size: 16px;
      width: 250px;
    }
  }
}

// *For Stripe
.payment-select-box{
       border: 1px solid hsla(0, 0%, 100%, .6);
       display: flex;
       justify-content: space-between;
       align-items: center;
       margin: 10px 0px;
       padding: 8px 10px;
       cursor: pointer;
       border-radius: 23px;
       -webkit-border-radius: 23px;
       -moz-border-radius: 23px;
       -ms-border-radius: 23px;
       -o-border-radius: 23px;

       &:hover{
        background-color: rgba($color: #fff, $alpha: 0.5);
        p{
          color: #003361 !important;
        }
       }

        p {
            margin: 0px !important;
          }
        
          .price {
            font-size: 20px !important;
            font-weight: 700;
          }
     .MuiRadio-root {
       color: rgba($color: #fff, $alpha: 0.5);
     }
                 
}

.selected-payment-box  {
  background-color: rgba($color: #fff, $alpha: 0.5);

  p {
    color: #003361 !important;
  }

         .MuiRadio-root {
           color: #003361;
         }
}

.selected-payment-box-disabled{
  opacity: 0.5;
  cursor: inherit;
  &:hover {
      background-color: transparent !important;
  
      p {
        color: #fff !important;
      }
    }
}
